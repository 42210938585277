import React from 'react';
import Navbar from './navbar';

const Layout = ({ children, loc_to_news, getUrl }) => (
	<React.Fragment>
		<Navbar loc_to_nav={loc_to_news} resultUrl={getUrl} />
		<main className='cortex-main'>{children}</main>
	</React.Fragment>
);
export default Layout;
