import React,{useState,useEffect} from "react"
import Link from "gatsby-link"
import '../components/layout.css'
import CortexLogo from '../components/logo'

const Navbar =(props)=>
{
const [toggle,setToggle]= useState(false);
const [controllY,setcontrollY]= useState(false);

const toggleBurger=()=>{
    setToggle(!toggle);
   }

function handleControl(){
    let scroll= window.scrollY;

    if(scroll>50)  
    return setcontrollY(true)
    else
    return setcontrollY(false)    
}

useEffect(()=>{
    window.addEventListener('scroll',handleControl);
    return()=>{
    window.removeEventListener('scroll',handleControl)
    }
},[]);

// Web View Normal and Active Styles
const linkStyles= controllY|| props.resultUrl?{color: "rgba(142, 142, 141)",fontSize:15,textDecoration:"none"}:{color:"rgba(255, 255, 255, 0.548)",fontSize:15,textDecoration:"none"};
const activeStyles=controllY  || props.resultUrl?{color:"black",fontSize:15,textDecoration:"none"}:{color:"white",fontSize:15,textDecoration:"none"};
// Mobile View Normal and Active Styles
const moblinkStyles= {color:"rgba(255, 255, 255, 0.548)",textDecoration:"none"};
const mobactiveStyles= {color:"white",textDecoration:"none"};

return (
    <React.Fragment>
        <nav className={controllY || props.resultUrl ?"cortex-navbar-scroll":"cortex-navbar"}>
            <CortexLogo loc_to_logo={props.loc_to_nav} getresultUrl={props.resultUrl} controll={controllY}/>
            <ul className="cortex-navbar-ul" >
                <li ><Link to="/"  style={linkStyles} activeStyle={activeStyles}> Home</Link></li>
                <li><Link to="/about/"  style={linkStyles} activeStyle={activeStyles}> About</Link></li>
                {/* Will use this to next version, do not delete */}             
                {/* {/* <li><Link to="/brands"  style={controllY || props.loc_to_nav=="/news" ?{color: "#3b3a39",opacity:0.6,fontSize:14,textDecoration:"none"}:{color:"rgba(255, 255, 255, 0.548)",fontSize:14,textDecoration:"none"}} activeStyle={controllY || props.loc_to_nav=="/news" ?{color:"black",textDecoration:"none"}:{color:"white",textDecoration:"none"}}>Brands</Link></li> */}
                <li><Link to="/news/" getProps={()=>props.loc_to_nav==="/news" || props.loc_to_nav==="/news/" ||props.resultUrl? {style:{...activeStyles}}:null} style={linkStyles}> News and Events</Link> </li> 
                <li><Link to="/contacts/"  style={linkStyles} activeStyle={activeStyles}> Contact</Link> </li>
            </ul>
        <div className="cortex-burger-container" onClick={()=>toggleBurger()}>
            {controllY || props.resultUrl ?
             <React.Fragment>
                <div className= { toggle? "change1" : "cortex-bar1-scroll" }></div>
                <div className= { toggle? "change2" : "cortex-bar2-scroll" }></div>
                <div className= { toggle? "change3" : "cortex-bar3-scroll" }></div>
             </React.Fragment>
            :
            <React.Fragment>
                <div className= { toggle? "change1" : "cortex-bar1" }></div>
                <div className= { toggle? "change2" : "cortex-bar2" }></div>
                <div className= { toggle? "change3" : "cortex-bar3" }></div>
            </React.Fragment>
            }
            </div>
            {toggle?
            <div className="cortex-burger-menu" >
                <div className="cortex-burger-nav"><Link to="/"  style={moblinkStyles} activeStyle={mobactiveStyles} onClick={()=>toggleBurger()}> Home</Link></div>
                <div className="cortex-burger-nav"><Link to="/about"  style={moblinkStyles} activeStyle={mobactiveStyles} onClick={()=>toggleBurger()}> About</Link></div>
                {/* Will use this to next version, do not delete */}                            
                {/* {/* <p className="cortex-burger-nav"><Link to="/brands"  style={{color:"rgba(255, 255, 255, 0.548)",textDecoration:"none"}} activeStyle={{color:"white",textDecoration:"none"}}onClick={()=>toggleBurger()}> Brands</Link></p> */}
                <div className="cortex-burger-nav"><Link to="/news"  getProps={()=>props.loc_to_nav==="/news" || props.loc_to_nav==="/news/" ||props.resultUrl? {style:{...mobactiveStyles}}:null}  style={moblinkStyles} onClick={()=>toggleBurger()}> News and Events</Link></div> 
                <div className="cortex-burger-nav"><Link to="/contacts"  style={moblinkStyles} activeStyle={mobactiveStyles}onClick={()=>toggleBurger()}> Contacts</Link></div>
            </div>: null}
        </nav>
    </React.Fragment>
)
}


export default Navbar;