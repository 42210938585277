import React from 'react';
import '../components/layout.css';
import FbIcon from '../images/cortex-fb-b.svg';
import InIcon from '../images/cortex-in-b-min.png';
import CortexOutLogo from '../images/cortex-logo-round.svg';
import { Link } from 'gatsby';

const Footer = ({ getUrl, loc_to_news }) => {
	const linkStyles = { color: 'rgba(142, 142, 141)', fontSize: 14, textDecoration: 'none' };
	const activeStyles = { color: 'black', textDecoration: 'none' };
	const decoratioNone={textDecoration:"none",color:"#3b3a39"};
	return (
		<footer id='cortex-footer'>
			<div className='cortex-footer-content'>
				<div className='cortex-footer-content-container-1'>
					<img src={CortexOutLogo} style={{ width: 50 }} alt='cortexIcon' />
					<Link to='/' style={linkStyles} activeStyle={activeStyles}>
						<li className='cortex-footer-content-li'>Home</li>
					</Link>
					<Link to='/about/' style={linkStyles} activeStyle={activeStyles}>
						<li className='cortex-footer-content-li'>About</li>
					</Link>
                    {/* Will use this to next version, do not delete */}
					{/* <Link to="/brands"><li className="cortex-footer-content-li">Brands</li></Link> */}
					<Link
						to='/news/'
						style={linkStyles}
						getProps={() =>
								loc_to_news === '/news' || loc_to_news === '/news/' || getUrl ? {
									style: { ...activeStyles }
								} :
								null}
					>
						<li className='cortex-footer-content-li'>News and Events</li>
					</Link>
					<Link to='/contacts/' style={linkStyles} activeStyle={activeStyles}>
						<li className='cortex-footer-content-li'>Contact</li>
					</Link>
					<div className='cortex-footer-content-social'>
						<a href='https://www.facebook.com/CortexTechnologies/' target='_blank' rel="noopener noreferrer">
							<img src={FbIcon} className='cortex-filter-content-color' alt='fbIcon' />
						</a>
						<a href='https://www.linkedin.com/company/cortex-technologies-corporation/' target='_blank' rel="noopener noreferrer">
							<img src={InIcon} className='cortex-filter-content-color' alt='inIcon' />
						</a>
					</div>
				</div>
				<div className='cortex-footer-content-container-2'>
					<div className='cortex-footer-content-policy-cc'>
						© Copyright Cortex Technologies Corporation. All rights reserved.
					</div>
                    {/* Will use this to next version, do not delete */}
					<Link to="/termsandconditions/" style={decoratioNone} ><div className="cortex-footer-content-policy-terms" >Terms and Conditions</div></Link>
					<Link to="/privacypolicy/" style={decoratioNone} >  <div className="cortex-footer-content-policy-privacy"  >Privacy Policy</div></Link>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
