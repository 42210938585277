import React from 'react';
import CortexLogoWh from '../images/Cortex Outline White.svg';
import CortexLogoBl from '../images/Cortex Outline.svg';
import '../components/layout.css';

const Logo = (props) => {
	return (
		<React.Fragment>
			{
				props.controll || props.getresultUrl ? 
        <img src={CortexLogoBl} id='cortex-logo' alt='cortexLogo' /> :
        <img src={CortexLogoWh} id='cortex-logo' alt='cortexLogo' />
        }
		</React.Fragment>
	);
};

export default Logo;
